// AboutUs.tsx
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import Footer from './components/Footer'; // Import the footer component you created earlier

import './App.css';

const AboutUs = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f0f2f5',
      }}
    >
      {/* AppBar */}
      {/* Reuse the AppBar from your App component here for consistency */}

      {/* Page Content */}
      <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center", display: "flex" }}>
        <Container sx={{ width: '100%', paddingTop: '3rem', paddingBottom: '3rem' }}>
          <Paper elevation={24} sx={{ background: 'rgba(28, 27, 32, 100)', padding: '2rem', borderRadius: '20px' }}>
            <Typography sx={{   fontSize: 'clamp(1.5rem, 2vw, 2rem)', fontWeight: 'bold', color: 'white', marginBottom: '1rem' }}>About Us</Typography>
            <Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: '#9ca3af', lineHeight: '1.5' }}>
              <span style={{ fontWeight: 'bold',   fontSize: 'clamp(1rem, 1rem, 1.2rem)', color: '#8884d8' }}>Fortune Finance - Revolutionizing ChatGPT Financial Decision-Making</span>
              <br />
              <br />
              Evolving from a bright idea to a full-fledged company, Fortune Finance was established in response to the growing need for accurate and real-time financial data to enhance decision-making capabilities for businesses and individuals alike. Our aim is to deliver up-to-the-minute financial information integrated with ChatGPT technology, empowering users with the knowledge they need to make informed financial decisions.
              <br />
              <br />
              <span style={{ fontWeight: 'bold',   fontSize: 'clamp(1rem, 1rem, 1.2rem)', color: '#8884d8' }}>A Passion For Innovation</span>
              <br />
              <br />
              Our founders, a group of dedicated professionals driven by the desire to address the gap in customized, data-driven financial decision-making, embarked on the journey of creating Fortune Finance. Harnessing the power of ChatGPT technology combined with real-time financial market data, financial statements, news, and a variety of data tools, we strive to disrupt the traditional approach to financial analysis.
              <br />
              <br />
              <span style={{ fontWeight: 'bold',   fontSize: 'clamp(1rem, 1rem, 1.2rem)', color: '#8884d8' }}>What Sets Us Apart</span>
              <br />
              <br />
              As a cutting-edge company, Fortune Finance differentiates itself by seamlessly integrating its advanced plugin system with ChatGPT. By incorporating expansive financial market data, our users gain a significant advantage when it comes to informed decision-making. Our commitment to innovation, confidentiality, and constant improvement ensures that Fortune Finance remains ahead of the curve in the rapidly evolving financial services industry.
              <br />
              <br />
              Moreover, we adhere to the principle of providing impartial and unbiased access to financial data. Fortune Finance does not offer financial or investment advice or advocate the purchase or sale of any security or investment. By focusing purely on data provision, we allow our users to maintain full control over their financial strategies and choices.
              <br />
              <br />
              <span style={{ fontWeight: 'bold',   fontSize: 'clamp(1rem, 1rem, 1.2rem)', color: '#8884d8' }}>Our Vision For The Future</span>
              <br />
              <br />
              At Fortune Finance, we believe in the transformative power of technology and data-driven approaches to finance. Our vision is to become the go-to source for integrating the latest ChatGPT plugins with real-time financial information, revolutionizing the way businesses and individuals access and utilize data.
              <br />
              <br />
              Our dedicated and passionate team constantly works to refine and expand our offerings, ensuring that Fortune Finance remains at the forefront of revolutionizing financial decision-making. By building long-lasting relationships with our users and partners, we will continue to forge a path towards a more data-driven and informed financial future.
              <br />
              <br />
              Should you have any questions or inquiries, please feel free to reach out to us at support@fortunefinance.io. We look forward to helping you navigate the multitude of financial data and decision-making processes with ease and efficiency.
            </Typography>
          </Paper>
        </Container>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default AboutUs;