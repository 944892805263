import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

const StockTicker = () => {
    const [stocks, setStocks] = useState<any[]>([]);
    const tickerContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchStocks = async () => {
      const url = `https://financialmodelingprep.com/api/v3/quote/AAPL,META,GOOGL,MSFT,NVDA,AMZN,TSLA,BABA,TSM,INTC,ASML,ADBE,CRM,ORCL?apikey=f9f2576fe286c53e09c0b46176bd58f2`;
      try {
        const response = await axios.get(url);
        setStocks(response.data);
      } catch (error) {
        console.error('Error fetching stocks:', error);
      }
    };

    fetchStocks();
    const interval = setInterval(fetchStocks, 60 * 5000); // Refresh every 5 minutes
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const setMoveDistance = () => {
      if (tickerContainerRef.current) {
        const containerWidth = tickerContainerRef.current.clientWidth;
        tickerContainerRef.current.style.setProperty('--move-distance', `-${containerWidth}px`);
      }
    };
    setMoveDistance();
    window.addEventListener('resize', setMoveDistance);
    return () => window.removeEventListener('resize', setMoveDistance);
  }, []);

  const renderStocks = () => {
    return stocks.map((stock, index) => (
      <React.Fragment key={stock.symbol}>
        <span
          className='stock-ticker-span'
          style={{ color: "white", marginRight: "1rem" }}
        >
          {stock.symbol} {stock.price.toFixed(2)} <span className={stock.changesPercentage >= 0 ? 'positive' : 'negative'} style={{ padding: "0px", marginLeft: "0.5rem" }}>{stock.changesPercentage.toFixed(2)}%</span>
        </span>
        {index === stocks.length && <span style={{ marginRight: "1rem" }}></span>}
      </React.Fragment>
    ));
  };

  return (
    <div className="stock-ticker">
      <div ref={tickerContainerRef} className="stock-ticker-container">
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
        {renderStocks()}
      </div>
    </div>
  );
};

export default StockTicker;