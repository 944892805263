import React, { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Hidden,
    Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Footer from './components/Footer';
import './App.css';

const categories = ['Portfolios', 'Stocks', 'Cryptocurrencies', 'Commodities', 'Forex', 'Shared'];

const cardData = [
    {
        title: 'Save Portfolio',
        description: 'Create and save an investment portfolio customized to match your unique financial goals. Seamlessly access and visualize the saved data in a user-friendly table format.',
        examplePrompt: 'Save me a portfolio that maximizes a budget of 1000USD and consists of 2 healthcare stocks, display the portfolio for me using a table.',
        exampleResponse: 'documentationImages/savePortfolio.png',
        category: 'Portfolios',
    },
    {
        title: 'Performance Portfolio',
        description: "Analyze a specific portfolio's performance with comprehensive, readily available data. Unleash the power of your investments using actionable insights presented in a clean table format.",
        examplePrompt: 'What is the performance of my Tech Giants Portfolio?',
        exampleResponse: 'documentationImages/performancePortfolio.png',
        category: 'Portfolios',
    },
    {
        title: 'Portfolio Names',
        description: "Access a list of all your available portfolios curated based on your preferences. Make informed decisions with a bird's eye view of your investments and strategies.",
        examplePrompt: 'List all my portfolios.',
        exampleResponse: 'documentationImages/portfolioNames.png',
        category: 'Portfolios',
    },
    {
        title: 'Read Portfolio',
        description: "Effortlessly retrieve a specific saved portfolio to track investments and performance. Further optimize your investing journey with better organization and easy access.",
        examplePrompt: 'Show my Diversified Portfolio.',
        exampleResponse: 'documentationImages/showPortfolio.png',
        category: 'Portfolios',
    },
    {
        title: 'Update Portfolio',
        description: "Effortlessly update an existing portfolio with additional assets to stay current with your evolving investment strategies and financial targets.",
        examplePrompt: 'Add a tech stock that is likely to perform well during a period of economic instability to my Tech Giants Portfolio.',
        exampleResponse: 'documentationImages/updatePortfolio.png',
        category: 'Portfolios',
    },
    {
        title: 'Remove Asset from Portfolio',
        description: "Simplify your investment management by seamlessly removing an existing asset from a portfolio. Keep your investment strategies aligned with your financial vision.",
        examplePrompt: 'Remove the worst performing asset from my Tech Giants Portfolio.',
        exampleResponse: 'documentationImages/removeAsset.png',
        category: 'Portfolios',
    },
    {
        title: 'Delete Portfolio',
        description: "Remove any existing portfolio with ease, ensuring that your investment strategies and data remain up-to-date.",
        examplePrompt: 'Delete my Diversified Portfolio.',
        exampleResponse: 'documentationImages/deletePortfolio.png',
        category: 'Portfolios',
    },
    {
        title: 'Social Sentiment',
        description: "Stay ahead of the curve by uncovering social buzz and sentiments around specific stocks, crypto, or forex, drawn from real-time news data.",
        examplePrompt: 'What is the social sentiment on Alibaba?',
        exampleResponse: 'documentationImages/socialSentiment.png',
        category: 'Shared',
    },
    {
        title: 'Latest News',
        description: "Keep your finger on the pulse of the latest news updates for stocks, crypto, forex, or individual assets. Access vital information to make informed investment decisions.",
        examplePrompt: 'Recommend me 2 cryptocurrencies based on recent news.',
        exampleResponse: 'documentationImages/news.png',
        category: 'Shared',
    },
    {
        title: 'News on Individual Assets',
        description: "Stay informed with real-time news about specific crypto, stock, or forex pairs. Leverage up-to-date information to shape your investment strategies and maximize potential.",
        examplePrompt: 'Tell me recent news about Nvidia which could explain its recent increase in stock price.',
        exampleResponse: 'documentationImages/newsIndividual.png',
        category: 'Shared',
    },
    {
        title: 'Historical Price Data',
        description: "Dive deep into historical price data for companies, cryptocurrencies, forex pairs, and commodities. Extract actionable insights through comprehensive data presented in easy-to-read tables.",
        examplePrompt: "What analysis can you make based on Microsoft's 15 minute price data?",
        exampleResponse: 'documentationImages/historicalPrice.png',
        category: 'Shared',
    },
    {
        title: 'Historical Price Charts',
        description: "Visualize historical price data for companies, cryptocurrencies, forex pairs, and commodities using immersive, interactive chart experiences.",
        examplePrompt: "Show me Google's 1 minute price chart.",
        exampleResponse: 'documentationImages/historicalPriceChart.png',
        category: 'Shared',
    },
    {
        title: 'Stock Information',
        description: "Obtain comprehensive, real-time data on a specific stock with ease. Leverage these insights to make data-driven decisions and optimize your investment strategies.",
        examplePrompt: "Tell me about Tesla's stock using a table.",
        exampleResponse: 'documentationImages/stockInformation.png',
        category: 'Stocks',
    },
    {
        title: 'Stock Filter',
        description: "Receive personalized, AI-generated stock suggestions based on your unique criteria. Discover investment opportunities that align with your financial objectives.",
        examplePrompt: "Recommend me 3 stocks that you think will do well based on recent CPI data.",
        exampleResponse: 'documentationImages/stockRecommend.png',
        category: 'Stocks',
    },
    {
        title: 'Gainers, Losers, and Actives',
        description: "Stay updated with daily stock market movers, including gainers, losers, and most active stocks. Spot opportunities and track market trends with data presented in a table format.",
        examplePrompt: "Show me today's biggest gainers using a table.",
        exampleResponse: 'documentationImages/marketMovers.png',
        category: 'Stocks',
    },
    {
        title: 'ESG Ratings',
        description: "Evaluate a company's Environmental, Social, and Governance (ESG) performance over multiple years. Make responsible and ethical investment decisions that align with your values.",
        examplePrompt: "Compare the ESG Ratings of Exxon Mobil to Apple using a table.",
        exampleResponse: 'documentationImages/ESGRating.png',
        category: 'Stocks',
    },
    {
        title: 'Income Statements',
        description: "Examine income statements of companies from any year and filter by period (Annual/Quarter) to gain a comprehensive understanding of financial health and performance.",
        examplePrompt: "Describe Nvidia's financial position based on its 3 previous annual income statements.",
        exampleResponse: 'documentationImages/incomeStatement.png',
        category: 'Stocks',
    },
    {
        title: 'Balance Sheets',
        description: "Analyze company balance sheets from any year, filter by period (Annual/Quarter), and assess financial stability to make informed investment decisions.",
        examplePrompt: "Describe Nvidia's financial position based on its most recent balance sheet.",
        exampleResponse: 'documentationImages/balanceSheet.png',
        category: 'Stocks',
    },
    {
        title: 'Cash Flow Reports',
        description: "Evaluate cash flow statements of companies from any year, filter by period (Annual/Quarter), and leverage the insights to inform your investments and strategies.",
        examplePrompt: "Describe Nvidia's financial position based on its most recent cash flow report.",
        exampleResponse: 'documentationImages/cashFlow.png',
        category: 'Stocks',
    },
    {
        title: 'Sector Performance',
        description: "Monitor daily performance of all sectors for the past 10 trading days. Identify market trends and capitalize on hidden opportunities through in-depth sector analysis.",
        examplePrompt: "What is the best performing sector?",
        exampleResponse: 'documentationImages/sectorPerformance.png',
        category: 'Stocks',
    },
    {
        title: 'Economic Indicators',
        description: "Access critical economic indicator data and unlock valuable insights into financial market performance to inform your investments and strategies.",
        examplePrompt: "What conclusions about the stability of the US economy can you draw based on 3 major economic indicators?",
        exampleResponse: 'documentationImages/economicIndicators.png',
        category: 'Stocks',
    },
    {
        title: 'Quantitative Fundamental Analysis',
        description: "Acquire financial data for a quantitative fundamental analysis of any stock. Make data-driven decisions to optimize your portfolio with these powerful insights.",
        examplePrompt: "Help conduct a quantitative fundamental analysis on Intel Corporation.",
        exampleResponse: 'documentationImages/fundamentalAnalysis.png',
        category: 'Stocks',
    },
    {
        title: 'Stock Competitors',
        description: "Discover key competitors of any stock to broaden your research and stay informed. Assess the competitive landscape to make well-informed investment decisions.",
        examplePrompt: "Who are Adobe's biggest competitors?",
        exampleResponse: 'documentationImages/competitors.png',
        category: 'Stocks',
    },
    {
        title: 'Cryptocurrency Prices',
        description: "Track real-time cryptocurrency prices using detailed data in a user-friendly table. Stay current with crypto market trends and make strategic investments.",
        examplePrompt: "What is Bitcoin's current price?",
        exampleResponse: 'documentationImages/cryptoPrice.png',
        category: 'Cryptocurrencies',
    },
    {
        title: 'Forex Pairs',
        description: "Access a comprehensive list of forex pair symbols. Keep your currency trading informed and efficient with this essential forex trading knowledge.",
        examplePrompt: "What are 10 important forex pairs?",
        exampleResponse: 'documentationImages/forexPairs.png',
        category: 'Forex',
    },
    {
        title: 'Exchange Rates',
        description: "Stay updated with real-time forex prices and exchange rates of any currency pair. Navigate the fast-paced world of forex trading confidently with timely data at your fingertips.",
        examplePrompt: "What's the current exchange rate for the EURUSD forex pair?",
        exampleResponse: 'documentationImages/exchangeRate.png',
        category: 'Forex',
    },
    {
        title: 'Commodity Symbols',
        description: "Access real-time price data of any commodity to make data-driven decisions in your commodities trading journey.",
        examplePrompt: "Provide me with a list of all available commodities.",
        exampleResponse: 'documentationImages/commodities.png',
        category: 'Commodities',
    },
    {
        title: 'Commodity Prices',
        description: "Uncover all available commodity symbols to expand your knowledge and stay well-informed in the world of commodities trading.",
        examplePrompt: "What's the current price for the commodity with the symbol FCUSX?",
        exampleResponse: 'documentationImages/commodityPrice.png',
        category: 'Commodities',
    },
    {
        title: 'Gold Prices',
        description: "Keep an eye on real-time gold price data presented in an easy-to-read table. Capitalize on golden opportunities and enhance your investment portfolio.",
        examplePrompt: "What's the current price for gold (XAUUSD)?",
        exampleResponse: 'documentationImages/goldPrice.png',
        category: 'Commodities',
    },
];

const WhatCanYouDo = () => {
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);

    const handleCategorySelection = (cat: string) => {
        setSelectedCategory(cat);
    };

    const filteredData = cardData.filter((item) => item.category === selectedCategory);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#f0f2f5',
            }}
        >
            <Box sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Container sx={{ width: '100%', paddingTop: '3rem', paddingBottom: '3rem' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <Typography
                                sx={{   fontSize: 'clamp(1.5rem, 2vw, 2rem)', fontWeight: 'bold', color: 'black', marginBottom: '1.5rem' }}
                            >
                                Documentation
                            </Typography>
                            <Hidden mdDown>
                                <List>
                                    {categories.map((cat, index) => (
                                        <ListItem
                                            button
                                            key={index}
                                            selected={selectedCategory === cat}
                                            onClick={() => handleCategorySelection(cat)}
                                            sx={{ marginBottom: '1rem', borderRadius: '3px' }}
                                        >
                                            <ListItemText primary={cat} sx={{ color: selectedCategory === cat ? 'black' : '#9ca3af' }} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Hidden>
                            <Hidden mdUp>
                                <FormControl fullWidth variant="outlined" size="small">
                                    <InputLabel htmlFor="category-select">Category</InputLabel>
                                    <Select
                                        value={selectedCategory}
                                        onChange={(event) => handleCategorySelection(event.target.value as string)}
                                        label="Category"
                                        id="category-select"
                                    >
                                        {categories.map((cat, index) => (
                                            <MenuItem key={index} value={cat}>
                                                {cat}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Hidden>
                        </Grid>
                        <Grid item xs={12} md={9} sx={{ }}>
                            <Typography
                                sx={{   fontSize: 'clamp(1.5rem, 2vw, 2rem)', fontWeight: 'bold', color: 'black', marginBottom: '1.5rem' }}
                            >
                                {selectedCategory}
                            </Typography>
                            <Container>
                                {filteredData.map((card, index) => (
                                    <div style={{ borderBottom: "1px solid #9ca3af", marginBottom: "1.5rem", paddingBottom: '1.5rem' }}>
                                        <Typography sx={{   fontSize: 'clamp(1.1rem, 1.1rem, 1.3rem)', fontWeight: 'bold' }}>{card.title}</Typography>
                                        <br />
                                        <Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)' }}>{card.description}</Typography>
                                        <br />
                                        <Typography sx={{   fontSize: 'clamp(1rem, 1rem, 1.2rem)', fontWeight: 'bold' }}>Example</Typography>
                                        <br />
                                        <Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: '#8884d8', fontWeight: 'bold' }}>Prompt:</Typography>
                                        <Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)' }}>{card.examplePrompt}</Typography>
                                        <br />
                                        <Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: '#8884d8', fontWeight: 'bold'}}>Response:</Typography>
                                        <Container sx={{ alignItems: "center", justifyContent: "center", display: "flex", paddingTop: "0.5rem" }}>
                                            <img style={{ maxWidth: '90%', borderRadius: "0.3rem" }} src={card.exampleResponse}/>
                                        </Container>
                                    </div>
                                ))}
                            </Container>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Footer />
        </Box>
    );
};

export default WhatCanYouDo;