import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Brush,
  ReferenceLine,
} from 'recharts';
import {
  Button,
  ButtonGroup,
  AppBar,
  Typography,
  Box,
  Container,
  Paper,
  Grid,
} from '@mui/material';
import Footer from './components/Footer';
import axios from 'axios';

interface ChartData {
  date: string;
  close: number;
}

const ChartPage: React.FC = () => {
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [company_ticker, setCompanyTicker] = useState("Stock");
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const FMP_API_KEY = 'f9f2576fe286c53e09c0b46176bd58f2'; // replace with your api key

  useEffect(() => {
    const fetchChartData = async () => {

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const ticker = urlParams.get('symbol')
      setCompanyTicker(ticker?.toLocaleUpperCase() as string)
      const period = urlParams.get('period')
      setSelectedPeriod(period as string)

      let dataUrl = '';

      if (period === 'daily') {
        dataUrl = `https://financialmodelingprep.com/api/v3/historical-price-full/${ticker}?serietype=line&apikey=${FMP_API_KEY}`;
      } else {
        dataUrl = `https://financialmodelingprep.com/api/v3/historical-chart/${period}/${ticker}?apikey=${FMP_API_KEY}`;
      }

      const response = await axios.get(dataUrl);

      if (period === "daily") {
        const data_slice = response.data.historical.slice(0, 2000)
        const data = data_slice.map((item: any) => ({
          date: item.date,
          close: item.close
        }));
        setChartData(data.reverse());
      } else {
        const data = response.data.map((item: any) => ({
          date: item.date,
          close: item.close
        }));
        setChartData(data.reverse());
      }
    };

    fetchChartData();
  }, [location]);

  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('period', period);
    navigate(`${location.pathname}?${urlParams.toString()}`);
  };

  const minVal = Math.min(...chartData.map(item => item.close));
  const maxVal = Math.max(...chartData.map(item => item.close));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f0f2f5',
      }}
    >
      {/* AppBar */}
      {/* ... */}

      {/* Page Content */}
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Container sx={{ width: '100%', paddingTop: '3rem', paddingBottom: '3rem' }}>
          <Paper
            elevation={24}
            sx={{
              background: 'rgba(28, 27, 32, 100)',
              padding: '2rem',
              borderRadius: '20px',
            }}
          >
            <Typography
              sx={{
                  fontSize: 'clamp(1.5rem, 2vw, 2rem)',
                fontWeight: 'bold',
                color: 'white',
                marginBottom: '1rem',
              }}
            >
              {company_ticker} Chart
            </Typography>
            <Box sx={{ marginTop: '1rem' }}>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <XAxis dataKey="date" />
                  <YAxis domain={[Math.round(minVal - (minVal * 0.05)), Math.round(maxVal + (maxVal * 0.05))]} />
                  <Tooltip />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Line type="monotone" dataKey="close" stroke="#8884d8" dot={false} />
                  <Brush dataKey="date" height={30} stroke="#8884d8" />
                  <ReferenceLine x="Page A" stroke="red" label="Max PV PAGE" />
                </LineChart>
              </ResponsiveContainer>
            </Box>
            <Container sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
              <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                <Grid item xs={6} sm={6} md={4} lg={1.7} sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <Button onClick={() => handlePeriodChange('1min')} sx={{
                    '&:hover': {
                      border: "1px solid #8884d8",
                    },
                    border: selectedPeriod === '1min' ? "1px solid #8884d8" : "1px solid transparent",
                    color: "white"
                  }}>1 Min</Button>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={1.7} sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <Button onClick={() => handlePeriodChange('5min')} sx={{
                    '&:hover': {
                      border: "1px solid #8884d8",
                    },
                    border: selectedPeriod === '5min' ? "1px solid #8884d8" : "1px solid transparent",
                    color: "white"
                  }}>5 Min</Button>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={1.7} sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <Button onClick={() => handlePeriodChange('15min')} sx={{
                    '&:hover': {
                      border: "1px solid #8884d8",
                    },
                    border: selectedPeriod === '15min' ? "1px solid #8884d8" : "1px solid transparent",
                    color: "white"
                  }}>15 Min</Button>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={1.7} sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <Button onClick={() => handlePeriodChange('30min')} sx={{
                    '&:hover': {
                      border: "1px solid #8884d8",
                    },
                    border: selectedPeriod === '30min' ? "1px solid #8884d8" : "1px solid transparent",
                    color: "white"
                  }}>30 Min</Button>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={1.7} sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <Button onClick={() => handlePeriodChange('1hour')} sx={{
                    '&:hover': {
                      border: "1px solid #8884d8",
                    },
                    border: selectedPeriod === '1hour' ? "1px solid #8884d8" : "1px solid transparent",
                    color: "white"
                  }}>1 Hour</Button>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={1.7} sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <Button onClick={() => handlePeriodChange('4hour')} sx={{
                    '&:hover': {
                      border: "1px solid #8884d8",
                    },
                    border: selectedPeriod === '4hour' ? "1px solid #8884d8" : "1px solid transparent",
                    color: "white"
                  }}>4 Hours</Button>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={1.7} sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <Button onClick={() => handlePeriodChange('daily')} sx={{
                    '&:hover': {
                      border: "1px solid #8884d8",
                    },
                    border: selectedPeriod === 'daily' ? "1px solid #8884d8" : "1px solid transparent",
                    color: "white"
                  }}>Daily</Button>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Container>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default ChartPage;