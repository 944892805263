import React, { useState, useEffect } from 'react';
import { Container, Typography, AppBar, Paper, Stack, Button, List, ListItem, Modal, Box, IconButton, Divider, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Snackbar, Alert } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './App.css';
import Footer from './components/Footer';

const DownloadOAuth = () => {
    const GOOGLE_CLIENT_ID = "369780171776-roks4u94gukrpun9dajb21aonom04q2b.apps.googleusercontent.com";
    const REDIRECT_URI = "https://fortunefinance.io/oauth"; //CHANGE IN GOOGLE CONSOLE TO FORTUNE FINANCE

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [alert, setAlert] = useState<{ open: boolean, message: string }>({ open: false, message: '' });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleGoogleSignIn = async () => {
        setLoading(true);

        const urlParams = new URLSearchParams(window.location.search);
        const state = urlParams.get("state");

        if (!state) {
            console.error("No state parameter found in the URL.");
            setAlert(prev => ({ ...prev, open: true, message: "No state parameter found in the URL. You must start the sign in flow from ChatGPT. Contact support@fortunefinance.io if the error persists." }));
            return;
        }

        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=profile email&access_type=offline&prompt=consent&state=${encodeURIComponent(state)}`;

        window.location.href = authUrl;
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        const state = urlParams.get("state");

        if (code && state) {
            setLoading(true);

            fetch("https://fortune-finance-api.plugin-port-api.com/oauth_callback", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    code: code,
                }),
            })
                .then(async (response) => {
                    if (response.ok) {
                        const user = await response.json();
                        const chatGptUrl = `https://chat.openai.com/aip/g-2c29098be10f47d514e5218129a9b496f6bd2524/oauth/callback?code=${user.uid}&state=${state}`;
                        window.location.replace(chatGptUrl);
                    } else {
                        console.error("Failed to send the authorization code to the oauth_callback endpoint.");
                        setAlert(prev => ({ ...prev, open: true, message: "Failed to send the authorization code to the authentication endpoint. Contact support@fortunefinance.io if the error persists." }));
                    }
                })
                .catch((error) => {
                    console.error("Error while sending the authorization code to the oauth_callback endpoint:", error);
                    setAlert(prev => ({ ...prev, open: true, message: "Error while sending the authorization code to the authentication endpoint. Contact support@fortunefinance.io if the error persists." }));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);

    const endpoints = [
        {
            name: 'Craft Your Personalized Portfolio',
            details: 'Save your own customized stock portfolio with ease and visualize the data in a user-friendly table, tailored specifically for your investment needs.',
        },
        {
            name: 'Access Your Portfolios',
            details: 'Easily retrieve any of your saved portfolios or explore the names of all available portfolios curated to your preferences.',
        },
        {
            name: 'Performance at Your Fingertips',
            details: 'Assess the performance of a specific portfolio with comprehensive data presented in a clean, table for your convenience.',
        },
        {
            name: 'Portfolio Management',
            details: 'Update, modify, or delete your existing portfolio with asset additions and removals to keep your investments up to date.',
        },
        {
            name: 'Pulse on Social Sentiments',
            details: 'Discover the social buzz around a stock, crypto, or forex based on real-time data aggregated from news sources to make informed decisions.',
        },
        {
            name: 'Stay Up-to-Date with News',
            details: 'Fetch the latest news updates on stocks, crypto, forex, or individual assets, ensuring you never miss a crucial piece of information.',
        },
        {
            name: 'Historic Price Insights',
            details: 'Access historical price data for companies, cryptocurrencies, forex pairs, and commodities presented in both easy-to-read tables and visually appealing charts.',
        },
        {
            name: 'Know Your Stocks',
            details: 'Get unparalleled, real-time data about a specific stock displayed in an informative table to enhance your financial strategies.',
        },
        {
            name: 'Tailor-Made Stock Recommendations',
            details: 'Receive personalized stock suggestions based on your individual criteria through the power of ChatGPT, ensuring your investment aligns with your goals.',
        },
        {
            name: 'Market Movers',
            details: 'Stay informed with daily stock data on gainers, losers, or most active stocks delivered in an easily digestible table.',
        },
        {
            name: 'Explore ESG Ratings',
            details: "Analyze a company's ESG rating over various years, empowering you to invest responsibly and ethically.",
        },
        {
            name: 'Financial Statement Analysis',
            details: "Deep dive into a company's income statement, balance sheet, and cash flow reports for any period, helping you make data-driven investment decisions.",
        },
        {
            name: 'Sector Performance Review',
            details: 'Evaluate daily performance of all sectors for the past 15 trading days, allowing you to analyze market trends and spot potential opportunities.',
        },
        {
            name: 'Deeper Economic Insights',
            details: 'Obtain data on essential economic indicators to help you better understand financial market performance, dynamics, and impact on your investments.',
        },
        {
            name: 'Quantitative Fundamentals',
            details: 'Retrieve financial data for a quantitative fundamental analysis on any stock, arming you with valuable insights for your financial strategies.',
        },
        {
            name: 'Competition Watch',
            details: 'Identify the key competitors of any stock to empower your research and broaden your potential investment horizon.',
        },
        {
            name: 'Cryptocurrency Price Explorer',
            details: 'Monitor real-time cryptocurrency prices with detailed data presented in a user-friendly table.',
        },
        {
            name: 'Forex Trading Knowledge',
            details: 'Access all relevant forex pair symbols, real-time exchange rates, and forex prices, equipping you with the necessary tools for successful currency trading.',
        },
        {
            name: 'Commodity Market Intel',
            details: 'Discover all available commodity symbols and fetch real-time price data for informed decision-making in the world of commodities trading.',
        },
        {
            name: 'The Gold Standard',
            details: 'Track real-time gold prices and evaluate the investment potential with meticulously crafted data presented in a table.',
        }

    ];

    return (
        <Box position="relative">
            {loading && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        zIndex: "100"
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    backgroundColor: '#f0f2f5',
                }}
            >
                <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center", display: "flex" }}>
                    <Container sx={{ width: 'auto', paddingTop: '3rem', paddingBottom: '3rem' }}>
                        <Paper elevation={24} sx={{ background: 'rgba(28, 27, 32, 100)', padding: '2rem', borderRadius: '20px' }}>
                            <Typography sx={{   fontSize: 'clamp(1.5rem, 2vw, 2rem)', fontWeight: 'bold', color: 'white', marginBottom: '1rem', textAlign: 'center' }}>Download</Typography>
                            <Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: '#9ca3af', lineHeight: '1.5', textAlign: 'center', padding: '0.5rem' }}>
                                1. Sign in with Google
                                <br />
                                2. Start making informed financial decisions
                            </Typography>
                            <Typography onClick={handleOpen} sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: '#9ca3af', lineHeight: '1.5', textAlign: 'center', padding: '0.5rem', textDecoration: 'underline', cursor: 'pointer' }}>What can Fortune Finance do?</Typography>
                            <Box sx={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                <img
                                    src="btn_google_signin_light_normal_web.png"
                                    onClick={handleGoogleSignIn}
                                    style={{ width: '50%', height: 'auto', cursor: 'pointer', marginTop: "1rem" }}
                                />
                            </Box>
                        </Paper>
                    </Container>
                </Box>
                <Footer />
            </Box >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    sx={{
                        background: 'rgba(28, 27, 32, 100)',
                        borderRadius: '20px',
                        width: '70%',
                        padding: '2rem',
                        textAlign: 'center',
                        outline: "none",
                        border: "none",
                        '@media (min-width: 768px)': {
                            padding: '3rem',
                        },
                    }}
                >
                    <Typography
                        id="modal-modal-title"
                        sx={{
                              fontSize: 'clamp(1.5rem, 2vw, 2rem)',
                            fontWeight: 'bold',
                            color: 'white',
                            marginBottom: '1rem',
                        }}
                    >
                        What can Fortune Finance do?
                    </Typography>
                    <Container sx={{ maxHeight: "40vh", overflowY: "scroll" }}>
                        {endpoints.map((tip, index) => (
                            <Accordion key={index} sx={{
                                background: 'none',
                                marginBottom: '1rem',
                                border: "solid 1px transparent",
                                borderRadius: "5px",
                                '@media (min-width: 768px)': {
                                    '&:hover': {
                                        border: "solid 1px #8884d8",
                                    },
                                },

                            }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                    aria-controls={`tip${index}-content`}
                                    id={`tip${index}-header`}
                                >
                                    <Typography sx={{   fontSize: 'clamp(1rem, 1rem, 1.2rem)', fontWeight: 'bold', color: 'white', textAlign: 'left' }}>{tip.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: '#9ca3af', lineHeight: '1.5', textAlign: 'left' }}>{tip.details}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Container>
                </Paper>
            </Modal>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={() => setAlert(prev => ({ ...prev, open: false }))}>
                <Alert onClose={() => setAlert(prev => ({ ...prev, open: false }))} severity="error">
                    {alert.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default DownloadOAuth;