import React, { useState, useEffect } from 'react';
import { Container, Typography, AppBar, Paper, Stack, Button, List, ListItem, Modal, Box, IconButton } from "@mui/material";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


import './App.css';

import HamburgerMenu from './components/HamburgerMenu';
import RoutesWrapper from './RoutesWrapper';

function App() {
  
  return (
    <Router>
      <Box
        sx={{
          backgroundColor: '#f0f2f5',
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden', // Add this line
        }}
      >
        <HamburgerMenu logoSrc="logo-no-background.png" />
        <RoutesWrapper />
      </Box>
    </Router>
  );
}

export default App;
