import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Article from '@mui/icons-material/Article';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import DownloadIcon from '@mui/icons-material/Download';

import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider, User, signOut, onAuthStateChanged } from "firebase/auth";

interface Props {
    logoSrc: string;
}

const HamburgerMenu: React.FC<Props> = ({ logoSrc }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <AppBar
                position="static"
                sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    padding: '0.5rem',
                    background: 'rgba(28, 27, 32, 100)',
                    '@media (min-width: 768px)': {
                        padding: '0.5vw',
                    },
                }}
            >
                <Stack direction='row' sx={{ maxWidth: "100%", width: "100%", display: "flex" }}>
                    <Box sx={{ width: "50%", alignItems: "left", display: "flex" }}>
                        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <img src={logoSrc} alt="logo" style={{ width: '32vh' }} />
                        </Link>
                    </Box>
                    <Box sx={{ width: "50%", alignItems: "right", display: "flex", justifyContent: "flex-end" }}>
                        {!menuOpen && (<IconButton edge="end" color="inherit" onClick={toggleMenu} sx={{ paddingRight: "2vw" }}>
                            <MenuIcon />
                        </IconButton>
                        )}
                    </Box>
                </Stack>
            </AppBar>
            <Drawer anchor="right" open={menuOpen} onClose={toggleMenu} sx={{ '& .MuiPaper-root': { height: 'auto', right: "3vw", top: "2vh", background: "rgba(28, 27, 32, 100)", color: "white", padding: "1.2rem", borderRadius: "20px" } }}>
                <List sx={{ height: "auto" }}>
                    <ListItem button component={Link} to="/tips" onClick={toggleMenu} sx={{   }}>
                        <IconButton edge="start" color="inherit" aria-label="menu">
                            <LightbulbIcon />
                        </IconButton>
                        <Typography sx={{ fontSize: "clamp(1rem, 1rem, 1.2rem)", alignItems: "right" }}>Tips</Typography>
                    </ListItem>
                    <ListItem button component={Link} to="/documentation" onClick={toggleMenu}>
                        <IconButton edge="start" color="inherit" aria-label="menu">
                            <Article />
                        </IconButton>
                        <Typography sx={{ fontSize: "clamp(1rem, 1rem, 1.2rem)", alignItems: "right" }}>Documentation</Typography>
                    </ListItem>
                    <ListItem button component={Link} to="/download" onClick={toggleMenu}>
                        <IconButton edge="start" color="inherit" aria-label="menu">
                            <DownloadIcon />
                        </IconButton>
                        <Typography sx={{ fontSize: "clamp(1rem, 1rem, 1.2rem)", alignItems: "right" }}>Download</Typography>
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
};

export default HamburgerMenu;
