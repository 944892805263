import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


import './App.css';

import Footer from './components/Footer';
import AboutUs from './AboutUs';
import Contact from './Contact';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import StockTicker from './components/StockTicker';
import DownloadOAuth from './DownloadOAuth';
import HamburgerMenu from './components/HamburgerMenu';
import Account from './Account';
import ChartPage from './ChartPage';
import Tips from './Tips';
import WhatCanYouDo from './WhatCanYouDo';
import useScrollToTop from './components/useScrollToTop';
import Home from './Home';
import HowToDownload from './HowToDownload';

const RoutesWrapper: React.FC = () => {
    useScrollToTop();

    
    return (
        <Routes>
            <Route path="/" element={<>
                <Home />
            </>} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/oauth" element={<DownloadOAuth />} />
            <Route path="/account" element={<Account />} />
            <Route path="/chart" element={<ChartPage />} />
            <Route path="/tips" element={<Tips />} />
            <Route path="/documentation" element={<WhatCanYouDo />} />
            <Route path="/download" element={<HowToDownload />} />
        </Routes>
    );
};

export default RoutesWrapper;