import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';

import Footer from './components/Footer';

import './App.css';

import { BarChart, Legend, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';


function createData(
    name: string,
    value: string,
) {
    return { name, value };
}


const Contact = () => {

    const rows = [
        createData('Membership :', 'President'),
        createData('Price :', '$14.99'),
        createData('Plan :', 'Monthly'),
    ];
    

    const data = [
        { name: 'Apr 1', 'Social Sentiment': 240, 'Fundamental Analysis': 160 },
        { name: 'Apr 2', 'Social Sentiment': 139, 'Fundamental Analysis': 221 },
        { name: 'Apr 3', 'Social Sentiment': 280, 'Fundamental Analysis': 229 },
        { name: 'Apr 4', 'Social Sentiment': 390, 'Fundamental Analysis': 200 },
        { name: 'Apr 5', 'Social Sentiment': 480, 'Fundamental Analysis': 218 },
        { name: 'Apr 6', 'Social Sentiment': 349, 'Fundamental Analysis': 250 },
        { name: 'Apr 7', 'Social Sentiment': 430, 'Fundamental Analysis': 210 },
        { name: 'Apr 8', 'Social Sentiment': 240, 'Fundamental Analysis': 160 },
        { name: 'Apr 9', 'Social Sentiment': 139, 'Fundamental Analysis': 221 },
        { name: 'Apr 10', 'Social Sentiment': 280, 'Fundamental Analysis': 229 },
        { name: 'Apr 11', 'Social Sentiment': 390, 'Fundamental Analysis': 200 },
        { name: 'Apr 12', 'Social Sentiment': 480, 'Fundamental Analysis': 218 },
        { name: 'Apr 13', 'Social Sentiment': 349, 'Fundamental Analysis': 250 },
        { name: 'Apr 14', 'Social Sentiment': 430, 'Fundamental Analysis': 210 },
        { name: 'Apr 15', 'Social Sentiment': 240, 'Fundamental Analysis': 160 },
        { name: 'Apr 16', 'Social Sentiment': 139, 'Fundamental Analysis': 221 },
        { name: 'Apr 17', 'Social Sentiment': 280, 'Fundamental Analysis': 229 },
        { name: 'Apr 18', 'Social Sentiment': 390, 'Fundamental Analysis': 200 },
        { name: 'Apr 19', 'Social Sentiment': 480, 'Fundamental Analysis': 218 },
        { name: 'Apr 20', 'Social Sentiment': 349, 'Fundamental Analysis': 250 },
        { name: 'Apr 21', 'Social Sentiment': 430, 'Fundamental Analysis': 210 },
        { name: 'Apr 22', 'Social Sentiment': 240, 'Fundamental Analysis': 160 },
        { name: 'Apr 23', 'Social Sentiment': 139, 'Fundamental Analysis': 221 },
        { name: 'Apr 24', 'Social Sentiment': 280, 'Fundamental Analysis': 229 },
        { name: 'Apr 25', 'Social Sentiment': 390, 'Fundamental Analysis': 200 },
        { name: 'Apr 26', 'Social Sentiment': 480, 'Fundamental Analysis': 218 },
        { name: 'Apr 27', 'Social Sentiment': 349, 'Fundamental Analysis': 250 },
        { name: 'Apr 28', 'Social Sentiment': 430, 'Fundamental Analysis': 210 },
        { name: 'Apr 29', 'Social Sentiment': 349, 'Fundamental Analysis': 250 },
        { name: 'Apr 30', 'Social Sentiment': 430, 'Fundamental Analysis': 210 },
      ];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#f0f2f5',
            }}
        >
            <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center", display: "flex" }}>
                <Container sx={{ width: '100%', paddingTop: '3rem', paddingBottom: '3rem', justifyContent: "center", display: "flex" }}>
                    <Paper elevation={24} sx={{
                        background: 'rgba(28, 27, 32, 100)',
                        padding: '3rem',
                        borderRadius: '20px',
                        width: "90%",
                        '@media (min-width: 768px)': {
                            width: '60%',
                        },
                    }}>
                        <Typography sx={{   fontSize: '2rem', fontWeight: 'bold', color: 'white', marginBottom: '1rem', textAlign: "center" }}>Account</Typography>
                        <Box sx={{ textAlign: "left" }}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" sx={{   fontSize: 'clamp(0.8rem, 1vw, 1rem)', color: '#9ca3af', lineHeight: '1.5', textAlign: "left", paddingBottom: "1rem" }}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right" sx={{   fontSize: 'clamp(0.8rem, 1vw, 1rem)', color: '#9ca3af', lineHeight: '1.5', textAlign: "left", paddingBottom: "1rem" }}>{row.value}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                        <Button
                            variant="contained"
                            sx={{
                                background:
                                    'radial-gradient(circle, #8884d8 0%, #064e89 100%)',
                                width: '100%',
                                height: '20%',
                                padding: '0.5rem',
                                borderRadius: '10px',
                                  fontSize: 'clamp(1rem, 1vw, 1rem)',
                                marginTop: '1rem',
                                '&:hover': {
                                    transform: 'scale(1.01)',
                                    transition: 'transform 0.2s ease',
                                },
                                '@media (min-width: 768px)': {
                                    width: '100%',
                                    padding: '0.8rem',

                                },
                            }}
                        >
                            Update
                        </Button>
                    </Paper>
                </Container>
            </Box>
            <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center", display: "flex" }}>
                <Container sx={{ width: '100%', paddingTop: '3rem', paddingBottom: '3rem', justifyContent: "center", display: "flex" }}>
                    <Paper elevation={24} sx={{
                        background: 'rgba(28, 27, 32, 100)',
                        padding: '3rem',
                        borderRadius: '20px',
                        width: "90%",
                        '@media (min-width: 768px)': {
                            width: '100%',
                        },
                    }}>
                        <Typography sx={{   fontSize: '2rem', fontWeight: 'bold', color: 'white', marginBottom: '1rem', textAlign: "center" }}>Usage</Typography>
                        <ResponsiveContainer width="100%" height={300} >
                            <BarChart margin={{ top: 5, right: 20, left: 10, bottom: 5 }} data={data} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Social Sentiment" fill="#8884d8" />
                                <Bar dataKey="Fundamental Analysis" fill="#064e89" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Container>
            </Box>
            <Footer />
        </Box>
    );
};

export default Contact;