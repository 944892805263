import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';


const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                background: 'rgba(28, 27, 32, 100)',
                paddingTop: '2rem',
                paddingBottom: '2rem',
                marginTop: '3rem',
                textAlign: 'center',
            }}
        >
            <Typography
                sx={{
                      fontSize: 'clamp(1rem, 1rem, 1.2rem)',
                    color: 'white',
                    fontWeight: 'bold',
                    marginBottom: '1rem',
                }}
            >
                <RouterLink
                    to="/"
                    style={{
                        color: 'white',
                        textDecoration: 'none',
                    }}
                >
                    Fortune Finance
                </RouterLink>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                <RouterLink
                    to="/about-us"
                    style={{
                        color: 'white',
                        textDecoration: 'none',
                          fontSize: 'clamp(0.8rem, 0.9rem, 1rem)',
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem"
                    }}
                >
                    About Us
                </RouterLink>
                <RouterLink
                    to="/contact"
                    style={{
                        color: 'white',
                        textDecoration: 'none',
                          fontSize: 'clamp(0.8rem, 0.9rem, 1rem)',
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem"
                    }}
                >
                    Contact
                </RouterLink>
                <RouterLink
                    to="/privacy-policy"
                    style={{
                        color: 'white',
                        textDecoration: 'none',
                          fontSize: 'clamp(0.8rem, 0.9rem, 1rem)',
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem"
                    }}
                >
                    Privacy Policy
                </RouterLink>
                <RouterLink
                    to="/terms-of-service"
                    style={{
                        color: 'white',
                        textDecoration: 'none',
                          fontSize: 'clamp(0.8rem, 0.9rem, 1rem)',
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem"
                    }}
                >
                    Terms of Service
                </RouterLink>
            </Box>
            <Typography
                sx={{
                      fontSize: 'clamp(0.7rem, 0.8rem, 0.9rem)',
                    color: 'white',
                    marginBottom: '0.5rem',
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem"
                }}
            >
                &copy; {new Date().getFullYear()} Fortune Finance. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;