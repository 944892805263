import React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import Footer from './components/Footer';

import './App.css';

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f0f2f5',
      }}
    >
      {/* AppBar */}
      {/* Reuse the AppBar from your App component here for consistency */}

      {/* Page Content */}
      <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center", display: "flex" }}>

        {/* Page Content */}
        <Container sx={{ width: '100%', paddingTop: '3rem', paddingBottom: '3rem' }}>
          <Typography sx={{   fontSize: 'clamp(1.5rem, 2vw, 2rem)', fontWeight: 'bold', color: 'black', marginBottom: '1rem' }}>Privacy Policy</Typography>
          <Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: 'black', lineHeight: '1.5', textAlign: "justify" }}>
            This Privacy Policy governs the manner in which Fortune Finance collects, uses, maintains, and discloses information collected from users (each, a "User") of the Fortune Finance application ("App"). This privacy policy applies to the App and all products and services offered by Fortune Finance.
            <br />
            <br />
            1. Personal identification information. We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users access our App, register on the App, fill out a form, and in connection with other activities, services, features, or resources we make available on our App. Users may be asked for, as examples: name, email address, company, credit card information, and more. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, except that it may prevent them from engaging in certain App-related activities.
            <br />
            <br />
            2. Non-personal identification information. We may collect non-personal identification information about Users whenever they interact with our App. Non-personal identification information may include the device name, the type of device, and technical information about Users' means of connection to our App, such as the operating system and the Internet service providers utilized, and other similar information.
            <br />
            <br />
            3. App usage cookies. Our App may use "cookies" to enhance User experience. Cookies can be managed through User device settings. Disabling cookies may affect the functionality of the App.
            <br />
            <br />
            4. How we use collected information. Fortune Finance may collect and use Users' personal information for the following purposes:
            <br />
            - To improve customer service
            <br />
            Information you provide helps us respond to your customer service requests and support needs more efficiently.
            <br />
            - To personalize user experience
            <br />
            We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our App.
            <br />
            - To improve our App
            <br />
            We may use feedback you provide to improve our products and services.
            <br />
            - To process payments
            <br />
            We may use the information Users provide about themselves when using the services of the App. We do not share this information with outside parties except to the extent necessary to provide the service.
            <br />
            - To send periodic emails
            <br />
            We may use the email address to respond to inquiries, questions, and/or other requests. If a User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email or User may contact us via our App.
            <br />
            <br />
            5. How we protect your information. We adopt appropriate data collection, storage and processing practices, and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our App.
            <br />
            <br />
            6. Sharing your personal information. We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above. We may use third-party service providers to help us operate our business and the App or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given us your permission.
            <br />
            <br />
            7. Third-party services. Users may find links to third-party services within the App. We do not control the content or links that appear on these services and are not responsible for the practices employed by them. In addition, these services may have their own privacy policies and customer service policies. Accessing and interaction with any such services is subject to their own terms and policies.
            <br />
            <br />
            8. Advertising. Ads appearing on our App may be delivered to Users by advertising partners, who may set cookies. This privacy policy does not cover the use of cookies by any advertisers. Users may opt out of interest-based advertising by visiting the Network Advertising Initiative (NAI) at: http://optout.networkadvertising.org/#!/‍
            <br />
            <br />
            9. Google Adsense. Some of the ads may be served by Google. Google's use of the DART cookie enables it to serve ads to Users based on their visit to our App and other sites on the Internet. DART uses "non personally identifiable information" and does NOT track personal information about you, such as your name, email address, physical address, etc. You may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy at https://policies.google.com/technologies/ads.
            <br />
            <br />
            10. Your California Privacy Rights & Do Not Sell My Personal Information. To exercise your California rights and request any of the actions or information under the California Consumer Privacy Act (CCPA), you can contact us at support@fortunefinance.io.
            <br />
            <br />
            11. Changes to this privacy policy. Fortune Finance has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
            <br />
            <br />
            12. Your acceptance of these terms. By using this App, you signify your acceptance of this policy and Terms of Service. If you do not agree to this policy, please do not use our App. Your continued use of the App following the posting of changes to this policy will be deemed your acceptance of those changes.
            <br />
            <br />
            13. Contacting us. If you have any questions about this Privacy Policy, the practices of this App, or your dealings with this App, please contact us via email here: support@fortunefinance.io.
            <br />
            <br />
            This document was last updated on May 27, 2023.
          </Typography>
          {/* <Paper elevation={24} sx={{ background: 'rgba(28, 27, 32, 100)', padding: '2rem', borderRadius: '20px' }}>
          </Paper> */}
        </Container>
      </Box>

      {/* Footer */}
      <Footer />
    </Box >
  );
};

export default PrivacyPolicy;