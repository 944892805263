import React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import Footer from './components/Footer';

import './App.css';

const TermsOfService = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#f0f2f5',
            }}
        >
            {/* AppBar */}
            {/* Reuse the AppBar from your App component here for consistency */}

            {/* Page Content */}
            <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center", display: "flex" }}>

                {/* Page Content */}
                <Container sx={{ width: '100%', paddingTop: '3rem', paddingBottom: '3rem' }}>
                    <Typography sx={{   fontSize: 'clamp(1.5rem, 2vw, 2rem)', fontWeight: 'bold', color: 'black', marginBottom: '1rem' }}>Terms of Service</Typography>
                    <Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: 'black', lineHeight: '1.5', textAlign: "justify" }}>
                        {/* Add your terms of service content here */}
                        These Terms of Service (the "Agreement") govern your use of fortunefinance.io and unless other terms and conditions expressly govern, other web sites from Fortune Finance that are made available from time to time, including without limitation, any Web pages, services, content, newsletters, databases, and information therein (collectively, the "Services").
                        <br />
                        <br />
                        BY CONTINUING TO USE THE SERVICES, YOU ARE INDICATING YOUR AGREEMENT TO BE BOUND BY THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE, FORTUNE FINANCE IS NOT WILLING TO PROVIDE YOU WITH ACCESS TO THE SERVICES AND YOU SHOULD IMMEDIATELY DISCONTINUE YOUR USE OF THE SERVICES.
                        <br />
                        <br />
                        1. Changes to the Agreement. We may modify this Agreement from time to time in our sole discretion and without notice to you. You should review this Agreement periodically to ensure familiarity with its then-current terms and conditions. Your continued use of the Services following any modification of this Agreement shall constitute your acceptance of such modifications to this Agreement.
                        <br />
                        <br />
                        2. Additional Terms of Service. Some Services or content sets available through the Services may have additional terms, conditions, and restrictions associated with them and will be posted on such Services or content sets. You agree that such additional terms, conditions, and restrictions will apply to your use of such Services or content sets and if there is a conflict with this Agreement, such additional terms, conditions, and restrictions will control.
                        <br />
                        <br />
                        3. Ownership; General Limitations on Use.
                        <br />
                        a. Ownership. You acknowledge and agree that the text, information, data, graphics, images, audio, video, design, organization, compilation, meta data, look and feel, advertising, and all other protectable intellectual property (the "Content") available through the Services is our property, the property of our advertisers or our third party licensors or suppliers (such licensors and suppliers are referred to as "Information Providers") and is protected by copyright and other intellectual property laws. You agree to access the Services manually by request and not programmatically by macro or other automated means.
                        <br />
                        b. Premium Products. Certain Services and content sets are only available after additional registration and/or payment of a fee.
                        <br />
                        <br />
                        4. Subscriber Accounts; Privacy. We require you to register to become a subscriber (a "Subscriber") before obtaining access to certain Services and certain functionality. When you register, you agree to provide us with true and accurate information about yourself. You understand that any information you provide as part of such registration or that we otherwise collect about you is subject to our Privacy Policy. It is your responsibility to choose your password wisely. We are not responsible for any user impersonating you on our Services. If you have concerns or believe that someone is using your password without your authority, please contact Fortune Finance support. We reserve the right to disclose any information about our Subscribers or users, including registration data, in order to comply with any applicable laws and/or requests under legal process, to operate our systems properly, to protect our property or rights, and to safeguard the interests of our user and other Subscribers.
                        <br />
                        <br />
                        5. Copyright/IP Policy. It is our policy to respond to notices of alleged infringement that comply with the Digital Millennium Copyright Act.
                        <br />
                        <br />
                        6. DISCLAIMERS OF WARRANTIES. YOU AGREE THAT YOUR ACCESS TO, AND USE OF, THE SERVICES AND THE CONTENT AVAILABLE THROUGH THE SERVICES IS ON AN "AS-IS", "AS AVAILABLE" BASIS AND WE AND OUR INFORMATION PROVIDERS SPECIFICALLY DISCLAIM ANY REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY REPRESENTATIONS OR WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. We do not give financial or investment advice or advocate the purchase or sale of any security or investment. You should always seek the assistance of a professional for financial or investment advice. Certain information and data provided in connection with these Services may be delayed as specified by financial exchanges or Information Providers. Fortune Finance and the Information Providers furnish the information available in connection with these Services without responsibility for accuracy and you agree that errors or omissions contained in such information shall not be made the basis for any claim, demand, or cause of action against Fortune Finance or any Information Provider. We may link to, or promote, web sites or services from other companies or offer you the ability to download software from other companies. You agree that we are not responsible for, and do not control, those web sites, services, and software. The Services include facts, views, opinions, and recommendations of individuals and organizations deemed of interest. Neither Fortune Finance nor the Information Providers guarantee the accuracy, completeness or timeliness of, or otherwise endorse, these facts, views, opinions, or recommendations, give investment advice, or advocate the purchase or sale of any security or investment.
                        <br />
                        <br />
                        7. LIMITATIONS ON LIABILITY. NEITHER FORTUNE FINANCE NOR ANY INFORMATION PROVIDER, NOR ANY OF THEIR RESPECTIVE SUBSIDIARIES, AFFILIATES, SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, AND LICENSORS (THE "EXCLUDED PARTIES") WILL BE LIABLE (JOINTLY OR SEVERALLY) TO YOU OR ANY OTHER PERSON AS A RESULT OF YOUR ACCESS OR USE OF THE SERVICES FOR INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOST SAVINGS, AND LOST REVENUES WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT, OR OTHER THEORY OF LIABILITY, EVEN IF ANY OF SUCH EXCLUDED PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY OF THE EXCLUDED DAMAGES, AND IRRESPECTIVE OF ANY FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY. IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, THEN THE EXCLUDED PARTIES' LIABILITY WILL BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY APPLICABLE LAW. You understand and agree that your use of the Services is at your sole risk.
                        <br />
                        <br />
                        8. INDEMNITY. You agree to indemnify and hold Fortune Finance and our respective subsidiaries, affiliates, officers, agents, employees, partners, and the Information Providers harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of Subscriber Content you upload, post, submit or otherwise make available through the Services, your use of the Services, and your violation of this Agreement.
                        <br />
                        <br />
                        9. General. Except as set forth in Section 2 above, this Agreement contains the final and entire agreement between us regarding your use of the Services and supersedes all previous and contemporaneous oral or written agreements regarding your use of the Services. This Agreement is personal to you, which means that you may not assign your rights or obligations under this Agreement to anyone. No third party is a beneficiary of this Agreement. You agree that this Agreement, as well as any and all claims arising from this Agreement will be governed by and construed in accordance with the laws of the State of California, United States of America applicable to contracts made entirely within the State of California and wholly performed in California State, without regard to any conflict or choice of law principles. The sole jurisdiction and venue for any litigation arising out of this Agreement will be an appropriate federal or state court located in California. This Agreement will not be governed by the United Nations Convention on Contracts for the International Sale of Goods.
                        <br />
                        <br />
                        These Terms of Service were last updated on May 27, 2023.

                    </Typography>
                    {/* <Paper elevation={24} sx={{ background: 'rgba(28, 27, 32, 100)', padding: '2rem', borderRadius: '20px' }}>
                    </Paper> */}
                </Container>
            </Box>

            {/* Footer */}
            <Footer />
        </Box>
    );
};

export default TermsOfService;