import React from 'react';
import { Box, Container, Paper, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Footer from './components/Footer';

const Tips = () => {
    const tips = [
        {
            name: 'Explore the Possibilities',
            details: 'Not sure what you can do with Fortune Finance? Simply ask ChatGPT about the various features and functionalities it offers to help you make the most of the plugin.',
        },
        {
            name: 'Avoid Overloading ChatGPT',
            details: 'For optimal results, send shorter and more focused messages rather than asking multiple questions at once. Excessive information in a single query may lead to less accurate responses.',
        },
        {
            name: 'Utilize Tables for Effective Visualization',
            details: 'If you require data to be presented in a tabular format for easier comprehension, just ask ChatGPT to display the information in a table.',
        },
        {
            name: 'Engage in a Conversation',
            details: 'We have found that ChatGPT offers the best outcomes and experiences when users engage in interactive conversations. Treat your interaction with ChatGPT as a natural conversation, asking questions and providing information as needed.',
        },
        {
            name: 'Verify Information from Reliable Sources',
            details: 'While Fortune Finance strives to provide accurate data, there may be instances where ChatGPT misinterprets the information. Always cross-check the responses with credible sources.',
        },
        {
            name: 'Fortune Finance is Not a Substitute for Financial Advice',
            details: 'Remember, Fortune Finance is designed as a financial data analysis tool that integrates with ChatGPT technology. It should not be considered a replacement for professional financial advisors.',
        },
        {
            name: 'Ensure Data Accuracy for Specific Companies',
            details: 'To receive accurate data pertaining to a particular company, use asset symbols such as AAPL, BTCUSD, or XAUUSD. Doing so will ensure that Fortune Finance retrieves the correct information for your query.',
        },
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#f0f2f5',
            }}
        >
            <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center", display: "flex" }}>
                <Container sx={{ width: '100%', paddingTop: '3rem', paddingBottom: '3rem' }}>
                    <Paper elevation={24} sx={{ background: 'rgba(28, 27, 32, 100)', padding: '2rem', borderRadius: '20px' }}>
                        <Typography sx={{   fontSize: 'clamp(1.5rem, 2vw, 2rem)', fontWeight: 'bold', color: 'white', marginBottom: '1rem' }}>Useful Tips</Typography>
                        {tips.map((tip, index) => (
                            <Accordion key={index} sx={{
                                background: 'none',
                                marginBottom: '1rem',
                                border: "solid 1px transparent",
                                borderRadius: "5px",
                                '@media (min-width: 768px)': {
                                    '&:hover': {
                                        border: "solid 1px #8884d8",
                                    },
                                },

                            }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                    aria-controls={`tip${index}-content`}
                                    id={`tip${index}-header`}
                                >
                                    <Typography sx={{   fontSize: 'clamp(1rem, 1rem, 1.2rem)', fontWeight: 'bold', color: 'white' }}>{tip.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: '#9ca3af', lineHeight: '1.5' }}>{tip.details}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Paper>
                </Container>
            </Box>
            <Footer />
        </Box>
    );
};

export default Tips;