import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Footer from './components/Footer';
import './App.css';
import { Grid } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const Step1 = () => (
    <>
        <span style={{ fontWeight: 'bold', fontSize: 'clamp(1rem, 1rem, 1.2rem)', color: '#8884d8' }}>Step 1: Navigate to ChatGPT and Ensure You Have a Plus Membership</span>
        <br />
        <br />
        Begin by visiting the <a href='https://chat.openai.com/?model=gpt-4-plugins' target="_blank" rel="noopener noreferrer" style={{ color: "#9ca3af" }}>ChatGPT</a> website. Make sure you have a Plus membership to gain access to the ChatGPT plugins.If you haven't upgraded yet, look for the membership options and select 'Plus'. Follow the prompts to complete the upgrade process.
        < br />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", paddingTop: "1rem" }}>
            <Paper elevation={6} sx={{
                width: "80%", padding: "0rem", display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: "20px", background: "#202123", '@media (min-width: 768px)': { width: '20%', }
            }}>
                <img src="chatgpt-plus.png" style={{ width: '100%', height: "100%", objectFit: "cover", borderRadius: "20px" }} />
            </Paper>
        </div>
    </>
);

const Step2 = () => (
    <>
        <span style={{ fontWeight: 'bold', fontSize: 'clamp(1rem, 1rem, 1.2rem)', color: '#8884d8' }}>Step 2: Search for the Fortune Finance Plugin</span>
        <br />
        <br />
        Once you have access, utilize the search function within the ChatGPT site. Enter 'Fortune Finance' into the search bar and initiate the search. You should be able to see the plugin among the search results. Click on it to proceed.
        <br />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", paddingTop: "1rem" }}>
            <Paper elevation={6} sx={{
                width: "80%", padding: "0rem", display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: "20px", background: "#202123", '@media (min-width: 768px)': { width: '40%', }
            }}>
                <img src="download-fortune-finance.png" style={{ width: '100%', height: "100%", objectFit: "cover", borderRadius: "20px", }} />
            </Paper>
        </div>
    </>
);

const Step3 = () => (
    <>
        <span style={{ fontWeight: 'bold', fontSize: 'clamp(1rem, 1rem, 1.2rem)', color: '#8884d8' }}>Step 3: Sign Up or Sign In to Fortune Finance</span>
        <br />
        <br />
        Once you land on the Fortune Finance website, you'll need to sign up or sign in to continue. You can do this easily by using Google authentication. Upon successful sign-up or sign-in, you'll be redirected back to the ChatGPT website to finalize the plugin integration.
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", paddingTop: "1rem" }}>
            <Paper elevation={6} sx={{
                width: "80%", padding: "0rem", display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: "20px", background: "#202123", '@media (min-width: 768px)': { width: '30%', }
            }}>
                <img src="signup-image.png" style={{ width: '100%', height: "100%", objectFit: "cover", borderRadius: "20px", }} />
            </Paper>
        </div>
    </>
);



const HowToDownload = () => {
    const [step, setStep] = useState(1);

    const handleNext = () => {
        if (step < 3) setStep(step + 1);
    };

    const handlePrev = () => {
        if (step > 1) setStep(step - 1);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#f0f2f5',
            }}
        >
            <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center", display: "flex" }}>
                <Container sx={{ width: '100%', paddingTop: '3rem', paddingBottom: '3rem' }}>
                    <Paper elevation={24} sx={{ background: 'rgba(28, 27, 32, 100)', padding: '2rem', borderRadius: '20px' }}>
                        <Typography sx={{ fontSize: 'clamp(1.5rem, 2vw, 2rem)', fontWeight: 'bold', color: 'white', marginBottom: '1rem' }}>How To Download Fortune Finance</Typography>
                        <Typography sx={{ fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: '#9ca3af', lineHeight: '1.5' }}>
                            {step === 1 && <Step1 />}
                            {step === 2 && <Step2 />}
                            {step === 3 && <Step3 />}
                            <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", paddingTop: "1rem" }}>
                                <Grid item xs={6} sx={{ justifyContent: "start", display: "flex", color: "red" }}>
                                    <IconButton onClick={handlePrev} disabled={step === 1} sx={{ color: step === 1 ? "#9ca3af" : "#8884d8", border: step === 1 ? "1px solid #9ca3af" : "1px solid #8884d8" }}><NavigateBeforeIcon /></IconButton>
                                </Grid>
                                <Grid item xs={6} sx={{ justifyContent: "end", display: "flex" }}>
                                    <IconButton onClick={handleNext} disabled={step === 3} sx={{ color: step === 3 ? "#9ca3af" : "#8884d8", border: step === 3 ? "1px solid #9ca3af" : "1px solid #8884d8" }}><NavigateNextIcon /></IconButton>
                                </Grid>
                            </Grid>
                        </Typography>
                    </Paper>
                </Container>
            </Box>
            <Footer />
        </Box>
    );
};

export default HowToDownload;
