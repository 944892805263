import React, { useState, useEffect } from 'react';
import { Container, Typography, AppBar, Paper, Stack, Button, List, ListItem, Modal, Box, IconButton } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { Close } from '@mui/icons-material';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';

import './App.css';

import Footer from './components/Footer';
import StockTicker from './components/StockTicker';


const Home = () => {
    const [stockData, setStockData] = useState<any[]>([]);

    const fetchStockData = async () => {
        const FMP_API_KEY = 'f9f2576fe286c53e09c0b46176bd58f2';
        const symbol = 'MSFT';
        const url = `https://financialmodelingprep.com/api/v3/historical-price-full/${symbol}?apikey=${FMP_API_KEY}`;

        try {
            const response = await axios.get(url);
            const data_slice = response.data.historical.slice(0, 150)
            const data = data_slice.map((item: any) => ({
                date: item.date,
                close: item.close
            }));
            setStockData(data.reverse());
        } catch (error) {
            console.error('Error fetching stock data:', error);
        }
    };

    const minVal = Math.min(...stockData.map(item => item.close));
    const maxVal = Math.max(...stockData.map(item => item.close));


    useEffect(() => {
        fetchStockData();
    }, []);


    const cardData = [
        {
            title: 'Craft Your Personalized Portfolio',
            description: 'Customize and save a well-structured stock portfolio tailored to your preferences.',
            modalTitle: 'Creating Your Personalized Portfolio',
            modalDescription: "Our application offers a seamless experience for you to create and save a custom-built stock portfolio that perfectly aligns with your investment goals. Once saved, you can visualize the data in a user-friendly table format."
        },
        {
            title: 'Tailor-Made Stock Recommendations',
            description: 'Receive personalized stock suggestions based on your unique criteria.',
            modalTitle: 'Stock Recommendations Just for You',
            modalDescription: "Leverage the power of ChatGPT to receive personalized stock recommendations based on your individual criteria. Make investments that perfectly align with your financial goals, taking advantage of our cutting-edge AI-driven suggestions."
        },
        {
            title: 'Deeper Economic Insights',
            description: 'Obtain data on key economic indicators.',
            modalTitle: 'Unlock Economic Indicator Data',
            modalDescription: "Retrieve valuable data on essential economic indicators, enabling you to better understand financial market performance, dynamics, and their impact on your investment strategy. Use these insights to make informed decisions and adjust your approach accordingly."
        },
    ];


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#f0f2f5',
            }}
        >
            <StockTicker />
            <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center", display: "flex", paddingTop: '1rem' }}>
                <Container sx={{ width: '100%', paddingTop: '3rem', paddingBottom: '3rem' }}>
                    <Paper elevation={24} sx={{ background: 'rgba(28, 27, 32, 100)', padding: '2rem', borderRadius: '20px' }}>
                        <Stack sx={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
                            <Typography sx={{   fontSize: 'clamp(1.5rem, 2vw, 2rem)', fontWeight: 'bold', color: 'white', textAlign: "center" }}>What Is Fortune Finance?</Typography>
                            <Typography sx={{   fontSize: 'clamp(1rem, 1rem, 1.2rem)', fontWeight: 'bold', color: 'white', marginBottom: '1rem', textAlign: "center" }}>and how can <span style={{ color: '#8884d8' }}>you</span> benefit from it?</Typography>
                            <Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: '#9ca3af', lineHeight: '1.5', textAlign: "justify", textAlignLast: "center" }}>
                                Fortune Finance is a cutting-edge ChatGPT plugin that provides real-time financial market data, financial statements, news, and a plethora of financial data tools at your fingertips. By seamlessly integrating with ChatGPT technology, we offer personalized and efficient access to crucial financial information, helping you navigate the complex financial landscape like never before.
                                <br />
                                <br />
                                Embrace the future of data-driven decision-making with Fortune Finance, where information is transformed into actionable insights. Unlock your potential and conquer the financial world today.</Typography>
                        </Stack>
                        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: 'center', paddingTop: "clamp(2rem, 2vw, 2rem)" }}>
                            <Typography sx={{   fontSize: 'clamp(1rem, 1rem, 1.2rem)', fontWeight: 'bold', color: '#9ca3af', marginBottom: '1rem' }}>
                                (MSFT)
                            </Typography>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={stockData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <XAxis dataKey="date" />
                                    <YAxis domain={[Math.round(minVal - (minVal * 0.05)), Math.round(maxVal + (maxVal * 0.05))]} />
                                    <Tooltip />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Line type="monotone" dataKey="close" stroke="#8884d8" dot={false} />
                                </LineChart>
                            </ResponsiveContainer>
                        </Box>
                    </Paper>
                </Container>
            </Box>
            <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center", display: "flex", paddingTop: '1rem' }}>
                <Container sx={{ width: '100%', paddingTop: '3rem', paddingBottom: '3rem' }}>
                    <Paper elevation={24} sx={{ background: 'rgba(28, 27, 32, 100)', padding: '2rem', borderRadius: '20px' }}>
                        <Typography sx={{   fontSize: 'clamp(1.5rem, 2vw, 2rem)', fontWeight: 'bold', color: 'white', marginBottom: '1rem', textAlign: "center" }}>What Can <span style={{ color: '#8884d8' }}>You</span> Do?</Typography>
                        <Container sx={{ maxWidth: "none", height: "auto", justifyContent: "center", alignItems: "center", display: "flex", paddingBottom: "1rem" }}>
                            <Grid container spacing={5} justifyContent="center" display="flex">
                                {cardData.map((card, index) => (
                                    <Grid key={index} xs={12} sm={4}>
                                        <Typography sx={{ color: 'white', textAlign: "center",   fontSize: 'clamp(1rem, 1rem, 1.2rem)' }}>{card.title}</Typography>
                                        <Typography sx={{ color: '#9ca3af', textAlign: "justify", textAlignLast: "center", paddingTop: '0.5rem',   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)' }}>{card.modalDescription}</Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                        <Link to="/documentation" style={{ textDecoration: 'none', color: 'inherit' }}><Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: 'white', lineHeight: '1.5', textAlign: 'center', paddingTop: '1rem', textDecoration: 'underline', cursor: 'pointer' }}>Find Out More</Typography></Link>
                    </Paper>
                </Container>
            </Box>
            <Footer />
        </Box>
    );
};

export default Home;