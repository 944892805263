import React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import Footer from './components/Footer';

import './App.css';

const Contact = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#f0f2f5',
            }}
        >
            {/* Page Content */}
            <Box sx={{ flexGrow: 1, justifyContent: "center", alignItems: "center", display: "flex" }}>
                <Container sx={{ width: '100%', paddingTop: '3rem', paddingBottom: '3rem' }}>
                    <Paper elevation={24} sx={{ background: 'rgba(28, 27, 32, 100)', padding: '2rem', borderRadius: '20px' }}>
                        <Typography sx={{   fontSize: 'clamp(1.5rem, 2vw, 2rem)', fontWeight: 'bold', color: 'white', marginBottom: '1rem' }}>Contact</Typography>
                        <Typography sx={{   fontSize: 'clamp(0.8rem, 0.9rem, 1rem)', color: '#9ca3af', lineHeight: '1.5' }}>
                            {/* Add your contact information here */}
                            If you have any questions, suggestions, or concerns, please feel free to reach out to us at:
                            <br />
                            <br />
                            Email: support@fortunefinance.com
                        </Typography>
                    </Paper>
                </Container>
            </Box>

            {/* Footer */}
            <Footer />
        </Box>
    );
};

export default Contact;